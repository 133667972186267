//Polyfill
import "core-js";

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;
import jQuery from "jquery";

const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

$(document).ready(function () {
  // .header-btnがクリックされたときの処理
  $(".header-btn").click(function () {
    // .header-btnと.header-spのopenクラスをトグル（追加/削除）
    $(".header-btn, .header-sp").toggleClass("open");
  });

  // .header-sp内のaタグがクリックされたときの処理
  $(".header-sp a").click(function () {
    // .header-btnと.header-spからopenクラスを削除
    $(".header-btn, .header-sp").removeClass("open");
  });
});

//ポップアップの挙動
document.addEventListener("DOMContentLoaded", () => {
  const popups = document.querySelectorAll(".popup");
  const triggers = document.querySelectorAll(".popup-trigger");
  const overlay = document.querySelector(".overlay");

  // Function to open popup
  const openPopup = (popupId) => {
    const popup = document.getElementById(popupId);
    if (popup) {
      popup.classList.add("show");
      overlay.classList.add("show-overlay");
    }
  };

  // Function to close all popups
  const closePopups = () => {
    popups.forEach((popup) => {
      popup.classList.remove("show");
      popup.style.transitionDelay = "0.1s"; // Delay to hide the popup
    });
    overlay.classList.remove("show-overlay");
    overlay.style.transitionDelay = "0.1s"; // Delay to hide the overlay
  };

  // Event listeners for triggers
  triggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const popupId = trigger.getAttribute("data-popup-target");
      openPopup(popupId);
    });
  });

  // Close button and overlay event listeners
  popups.forEach((popup) => {
    const closeBtn = popup.querySelector(".close-btn");
    closeBtn.addEventListener("click", closePopups);
  });
  overlay.addEventListener("click", closePopups);
});

//少しスクロールしたら表示
document.addEventListener("DOMContentLoaded", function () {
  // トップページかどうかをチェック
  if (window.location.pathname === "/" || window.location.pathname === "/index.html") {
    const header = document.querySelector(".header");
    const showHeaderAt = 700; // ヘッダーを表示するスクロール量（ピクセル）

    window.addEventListener("scroll", function () {
      if (window.scrollY > showHeaderAt) {
        header.classList.add("visible");
      } else {
        header.classList.remove("visible");
      }
    });
  }
});

//施工事例
const swiper = new Swiper(".worksSwiper", {
  loop: true,
  centeredSlides: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },
});